import axios from 'axios';
import { signTransaction } from '../hashconnect';
import getUserId from '../Redux/getUser';
export async function getUserAuthData(): Promise<any> {
    try {
        let data = localStorage.getItem("accessToken");
        if (data) {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: process.env.REACT_APP_API_BASE_URL + '/getUser/get',
                headers: {
                    'Authorization': 'Bearer ' + data
                }
            };
            const response = await axios.request(config)
            const userData = response.data.payload;
            return userData;
        }
    } catch (error) {

    }
}

export const updateToken = async (serials: number[], tokenId: string) => {
    try {
        let data = localStorage.getItem("accessToken");
        if (data) {
            const update = await fetch(process.env.REACT_APP_API_BASE_URL + '/updateToken/post', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + data
                },
                body: JSON.stringify({ serials: serials, tokenId: tokenId }),
            }).then((response) => response.json());
            return update.payload.payload.status
        }
    } catch (error) {

    }
}


async function getUserBalance() {
    try {
        const hashconnectData: any = localStorage.getItem("hashconnectData");
        const data: any = JSON.parse(hashconnectData)
        const accountId = getUserId()
        const response = await axios.get(process.env.REACT_APP_HEDERA_NODE + '/accounts/' + accountId)
        const balance = response.data.balance.balance;
        return balance;
    } catch (error) {
        // console.log(error)
    }
}


export async function claimToken(token: string) {
    try {
        const tokenData = { token: token }
        const balance = await getUserBalance();
        if (balance >= 1000000) {
            let data = localStorage.getItem("accessToken");
            if (data) {
                const tokens = await fetch(process.env.REACT_APP_API_BASE_URL + '/claimToken/post', {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + data
                    },
                    body: JSON.stringify({ tokenData }),
                }).then((response) => response.json());

                // console.log(tokens)
                const transBytes = tokens.payload.transBytes;
                const transaction = await signTransaction(transBytes)
                const status = {
                    status: transaction,
                    amount: tokens.payload.amount,
                    serials: tokens.payload.serials,
                    tokenId: tokens.payload.tokenId
                }
                return status;
            }
        }
    } catch (error) {

    }
}

export async function getTokens() {
    try {
        let data = localStorage.getItem("accessToken");
        if (data) {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: process.env.REACT_APP_API_BASE_URL + '/getTokens/get',
                headers: {
                    'Authorization': 'Bearer ' + data
                }
            };
            const token = await axios.request(config)
            return token.data.payload.tokens;
        }
    } catch (error) {

    }
}

export async function verify(receiveAuthData: string) {
    const verification = await fetch(process.env.REACT_APP_API_BASE_URL + '/getAuth/post', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: receiveAuthData,
    }).then((response) => response.json());

    return verification;
}

export async function getComicLink(name: string) {
    const data = await fetch(process.env.REACT_APP_API_BASE_URL + '/getComicLink/post', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "name": name
        }),
    }).then((response) => response.json());
    const link = data.payload.link;
    return link;



}

export async function getAuth() {
    const auth = await fetch(
        process.env.REACT_APP_API_BASE_URL + "/sendAuth/get"
    ).then((response) => response.json());
    return auth;
} 