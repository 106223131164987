import { useState, useEffect } from 'react';
import { claimToken, getComicLink, getUserAuthData, updateToken } from '../services/API';
import { addToClipBoard } from '../helpers/helpers';
import { useSelector } from 'react-redux';
import { saveUserData } from '../Redux/actions';
import { useDispatch } from 'react-redux';
import QRCode from "react-qr-code";
import '../modal.css';
import Modal from "react-modal";
import store from '../Redux/store';
import fileDownload from 'js-file-download'
import axios from 'axios'

import {
	pairHashpack,
	initConnection,
	disconnect,
	associate,
	disassociate,
	manualListener
} from '../hashconnect';
import updateUserData from '../Redux/updateUser';
import updateWalletData from '../Redux/updateWallet';

function Home() {

	const [isOpen, setIsOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [pairingString, setPairingString] = useState('');
	const [loadingMessage, setLoadingMessage] = useState("Loading ...");
	const [successFul, setSuccessful] = useState(false);
	const [successMessage, setSuccessMessage] = useState("");
	const [amount, setAmount] = useState(0);
	const userId = useSelector((state: any) => state.user.userId);
	const isFirstChapterAssociated = useSelector((state: any) => state.user.isFirstChapterAssociated)
	const isSecondChapterAssociated = useSelector((state: any) => state.user.isSecondChapterAssociated)
	const isThirdChapterAssociated = useSelector((state: any) => state.user.isThirdChapterAssociated)
	const isFirstChapterOwner = useSelector((state: any) => state.user.isFirstChapterOwner)
	const isSecondChapterOwner = useSelector((state: any) => state.user.isSecondChapterOwner)
	const isThirdChapterOwner = useSelector((state: any) => state.user.isThirdChapterOwner)
	const isFounder = useSelector((state: any) => state.user.isFounder)
	const isConnected = useSelector((state: any) => state.user.isConnected)
	const isFakeWallet = useSelector((state: any) => state.wallet.isFakeWallet)


	useEffect(() => {
		getUserData();
	}, []);

	async function closeFakeWalletModal() {
		updateWalletData(false)
	}

	async function getUserData() {
		try {
			setLoadingMessage("Fetching user Data")
			setLoading(true)
			let data = localStorage.getItem("accessToken");
			if (data) {
				let config = {
					method: 'get',
					maxBodyLength: Infinity,
					url: process.env.REACT_APP_API_BASE_URL + '/getUser/get',
					headers: {
						'Authorization': 'Bearer ' + data
					}
				};


				const authData = await getUserAuthData();
				const id = authData.id;
				if (id) {
					updateUserData(id, authData, true)
				}
				setLoading(false)
				setLoadingMessage("Loading...")

			} else {
				setLoading(false)
				setLoadingMessage("Loading...")
			}
		} catch (error) {

		}
	}



	async function browserExtension() {
		try {
			setLoadingMessage("Pairing...")
			setLoading(true)
			await pairHashpack();
			closeModal();
			setLoading(false)
			setLoadingMessage("Loading...")
		} catch (error) {

		}
	}

	async function disconnectBrowserExtension() {
		try {
			setLoadingMessage("Disconnecting...")
			setLoading(true)
			await disconnect();
			updateUserData("", [], false)
			setLoading(false)
			setLoadingMessage("Loading...")
		} catch (error) {

		}
	}

	async function openModal() {
		try {
			setIsOpen(true);
			const saveData = await initConnection()
			setPairingString(saveData.pairingString)
			manualListener(setIsOpen, setLoading)
		} catch (error) {

		}
	}

	async function closeModal() {
		setIsOpen(false);
	}


	async function claim(token: string) {
		try {
			setLoadingMessage("Generating Transaction...")
			setLoading(true)
			const operation: any = await claimToken(token);

			if (operation.status == true) {
				const status: any = await updateToken(operation.serials, operation.tokenId)
				if (status == true) {
					setSuccessMessage(" Token(s) sent successfully!")
					setSuccessful(true)
					showSuccessModal(1)
				} else {
					setSuccessMessage(" Something went wrong.")
					setSuccessful(false)
					showSuccessModal(1)
				}
			}
			setLoading(false)
			setLoadingMessage("Loading...")
		} catch (error) {

		}
	}
	async function showSuccessModal(tokens: any) {
		try {
			setAmount(tokens)
			// setSuccessful(true);
			await getUserData();
		} catch (error) {

		}
	}

	async function associateToken() {
		try {
			setLoading(true)
			await associate();
			setLoading(false)
			getUserData()
			// showSuccessModal(tokens)
		} catch (error) {

		}

	}

	async function disAssociateToken() {
		try {
			setLoading(true)
			await disassociate();
			setLoading(false)
			await getUserData()
			// showSuccessModal(tokens)
		} catch (error) {

		}

	}

	async function download(comic: string) {
		setLoadingMessage("Loading File...")
		setLoading(true)
		const s3FileURL = await getComicLink(comic + ".pdf");
		const filename = comic + ".pdf"
		await axios.get(s3FileURL, {
			responseType: 'blob',
		  })
		  .then((res) => {
			fileDownload(res.data, filename)
		  })
		setLoadingMessage("loading ...")
		setLoading(false)
	}

	return (
		<>
			<nav className="navbar navbar-expand-lg">
				<div className="container">
					<a className="logo" href="#">
						<img src="img/HeaderLogo.svg" alt="logo" />
					</a>

					<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
						aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
						<span className="icon-bar"><i className="fas fa-bars"></i></span>
					</button>
					<div className="collapse navbar-collapse" id="navbarSupportedContent">
						<ul className="navbar-nav ml-auto">
							<li className="nav-item">
								<a className="nav-link" href="/">Home</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" href="#about">About</a>
							</li>

							<li className="nav-item">
								<a className="nav-link" href="#how-to-claim">How To Claim</a>
							</li>

							<li className="nav-item">
								<a className="nav-link" href="#claim">Claim</a>
							</li>

							<li className="nav-item">
								<a className="nav-link" href="#join-us">Join us</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" href="#"> </a>
							</li>
							<li className="nav-item">

								{userId ? (

									<button className="butn bord curve mt-10 wow fadeInUp custom-btn text-white" id="accountid" onClick={disconnectBrowserExtension}> Disconnect</button>
								) : (
									<button className="butn bord curve mt-10 wow fadeInUp custom-btn text-white" id="accountid" onClick={openModal}>Connect Your Wallet</button>
								)

								}
							</li>
						</ul>
					</div>
				</div>
			</nav>

			<header className="particles circle-bg valign">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-10">
							<div className="cont text-center">
								<img src='/img/tracewar.png'></img>
								<div id="countdown">
									<ul>
										<li className="countdown"><span id="days"></span>days</li>
										<li className="countdown"><span id="hours"></span>Hours</li>
										<li className="countdown"><span id="minutes"></span>Minutes</li>
										<li className="countdown"><span id="seconds"></span>Seconds</li>
									</ul>
								</div>
								<h6 id='headline'></h6>
							</div>
						</div>
					</div>
				</div>

				<div>
					<div className="gradient-circle"></div>
					<div className="gradient-circle two"></div>
				</div>
				<div className="line bottom left"></div>
			</header>

			<section className="agency  position-re bigonesection" id='about'>
				<video autoPlay loop muted width="1280px" height="720">
					<source type="video/mp4" src="/videos/ash3.mp4" />
					<source type="video/webm" src="/videos/ash3.webm" />
					<source type="video/ogg" src="/videos/ash3.ogv" />
				</video>
				<div className='overlay'></div>
				<div className="container">
					<div className="row">
						<div className="col-lg-7">
							<div className="col-md-12">
								<img className="bigone" height="250px" src="img/bigone.png" alt="" />
							</div>
							<div className="line bottom right"></div>
						</div>
						<div className="line bottom right bottom-a"></div>
						<div className="col-lg-5 valign">
							<div className="content">
								<h4 className="wow about-title" data-splitting> ABOUT  <br></br><img className='trace' width="1450px" src='/img/tracewar.png'></img></h4>
								<p className="wow txt" data-splitting>Trace War is set in the Pacific Northwest after the war is lost against global warming. The remaining population has been divided along ideological lines into warring fiefdoms. You'll encounter The Trace, pockets of dark energy that scar the environment,
									mutated creatures affected by this unnatural energy, and warring enclaves vying for power over what remains of humankind!</p>

							</div>
						</div>
					</div>
				</div>
			</section>


			<section className="feat sub-bg section-padding" id='how-to-claim'>
				<div className="container">
					<div className="row">
						<div className="col-lg-8 col-md-10">
							<div className="sec-head">
								<h6 className="wow fadeIn" data-wow-delay=".5s">COMIC AIRDROP</h6>
								<h3 className="wow color-font">How To Claim</h3>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-3 col-md-6 items md-mb30">
							<div className="item wow fadeIn" data-wow-delay=".3s">
								<center>
									<div className='squared'>
										<div className='customFont'>1</div>
									</div>
								</center>
								<h5>Connect Your Wallet</h5>
								<p>Begin by connecting your digital wallet. This step helps identify and link your Information to the system.</p>
							</div>
						</div>
						<div className="col-lg-3 col-md-6 items active md-mb30">
							<div className="item wow fadeIn" data-wow-delay=".3s">
								<center>
									<div className='squared'>
										<div className='customFont'>2</div>
									</div>
								</center>
								<h5>Associate  The Tokens</h5>
								<p>Associate your Hashpack wallet with The NFT of The Comics.</p>
							</div>
						</div>
						<div className="col-lg-3 col-md-6 items sm-mb30">
							<div className="item wow fadeIn" data-wow-delay=".3s">
								<center>
									<div className='squared'>
										<div className='customFont'>3</div>
									</div>
								</center>
								<h5>Approve Transfer Transactions</h5>
								<p>Grant approval for the airdrop transfer transaction. Verify and confirm the details of the token
									transfer to complete the airdrop process securely and efficiently.</p>
							</div>
						</div>
						<div className="col-lg-3 col-md-6 items">
							<div className="item wow fadeIn" data-wow-delay=".3s">
								<center>
									<div className='squared'>
										<div className='customFont'>4</div>
									</div>
								</center>
								<h5>Receive Tokens</h5>
								<p>Once the transfer is approved, you'll receive the airdropped tokens directly into your
									Hedera wallet and will be allowed to Download The Comic.</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="min-area" id='claim'>
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="img">
								<img className="thumparallax-down" src="img/chapter1.jpg" alt="" />
							</div>
						</div>
						<div className="col-lg-6 valign">
							<div className="content">
								<h4 className="color-font">John Garvin's Trace War Issue #1</h4>
								<p className="wow txt" data-splitting>"While traveling the Easton Badlands, Ash Naranjo stops to refuel at a
									Trace, one of the many pockets of dark energy that scar what was once
									the Pacific Northwest. There he has an unexpected encounter that
									could forever change the course of his search for his missing sister."
								</p>
								<ul>
									<li className="wow fadeInUp" data-wow-delay=".2s">Author: John Garvin</li>
									<li className="wow fadeInUp" data-wow-delay=".4s">Illustrator: Paul Pelletier</li>
								</ul>

								<br></br>
								<div className='row'>
									{
										(isFirstChapterAssociated && isFirstChapterOwner && isFounder)
											?
											<div className='col-sm-6'>
												<p id='claimBtn'>Thank You For Participating in Our Airdrop.</p> 
											 
											</div>
											:
											(isFirstChapterAssociated && !isFirstChapterOwner && isFounder)
												?
												<div className='col-sm-6'>
													<button className="butn bord curve wow fadeInUp custom-btn text-white" onClick={(() => claim("firstChapter"))} data-wow-delay=".8s">
														<span>Claim Token</span>
													</button>
												</div>
												:
												(!isFirstChapterAssociated && !isFirstChapterOwner && isFounder)
													?
													<div className='col-sm-6'>
														<button className="butn bord curve wow fadeInUp custom-btn text-white" onClick={associateToken} data-wow-delay=".8s">
															<span>Associate Token</span>
														</button>
													</div>
													:
													(isFirstChapterAssociated === false && isFirstChapterOwner === false && isFounder === false && isConnected === true)
														?
														<div className='col-sm-6'>
															<p id='claimBtn'> Sorry, you are ineligible for this drop. </p>
														</div>
														:
														<p id='claimBtn'> </p>
									}

									<div className='col-md-6'>
										 <button className="butn bord curve wow fadeInUp custom-btn text-white" onClick={(() => download("first-chapter"))} data-wow-delay=".8s">
											<span>Download Comic</span>
										</button> 
									</div>
								</div>
								<br />
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="min-area">
				<div className="container">
					<div className="row">
						<div className="col-lg-6 valign">
							<div className="content">
								<h4 className="color-font">John Garvin's Trace War Issue #2</h4>
								<p className="wow txt" data-splitting>O"Ash Naranjo, now an elite Ghost agent, has completed his first deployment.
									Sent into Freeland- occupied territory to capture Ethox – a high-ranking
									Freeland officer wanted for interrogation – Ash was able to access one of
									their computers. Using Ethox’s credentials, Ash searched for, and found, three
									prisoners of war: Aurik, Isla, and Esme Naranjo. A fourth person, Zade Fernsell,
									was not in the database."
								</p>
								<ul>
									<li className="wow fadeInUp" data-wow-delay=".2s">Author: John Garvin</li>
									<li className="wow fadeInUp" data-wow-delay=".4s">Illustrator: Paul Pelletier</li>
								</ul>
								<br></br>
								<div className='row'>
									{
										(isSecondChapterAssociated && isSecondChapterOwner && isFounder)
											?
											<div className='col-sm-6'>
												<p id='claimBtn'>Thank You For Participating in Our Airdrop.</p>
											</div>
											:
											(isSecondChapterAssociated && !isSecondChapterOwner && isFounder)
												?
												<div className='col-sm-6'>
													<button className="butn bord curve wow fadeInUp custom-btn text-white" onClick={(() => claim("secondChapter"))} data-wow-delay=".8s">
														<span>Claim Token</span>
													</button>
												</div>
												:
												(!isSecondChapterAssociated && !isSecondChapterOwner && isFounder)
													?
													<div className='col-sm-6'>
														<button className="butn bord curve wow fadeInUp custom-btn text-white" onClick={associateToken} data-wow-delay=".8s">
															<span>Associate Token</span>
														</button>
													</div>
													:
													(isSecondChapterAssociated === false && isSecondChapterOwner === false && isFounder === false && isConnected === true)
														?
														<div className='col-sm-6'>
															<p id='claimBtn'> Sorry, you are ineligible for this drop.</p>
														</div>
														:
														<p id='claimBtn'> </p>
									}

									<div className='col-md-6'>
										 <button className="butn bord curve wow fadeInUp custom-btn text-white" onClick={(() => download("second-chapter"))} data-wow-delay=".8s"> 
											 <span>Download Comic</span> 
										</button>  
									</div>
									<br />
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="">
								<img className="thumparallax-down" src="img/chapter2.jpg" alt="" />
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="min-area">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="img">
								<img className="thumparallax-down" src="img/chapter3.jpg" alt="" />
							</div>
						</div>
						<div className="col-lg-6 valign">
							<div className="content">
								<h4 className="color-font">John Garvin's Trace War Issue #3</h4>
								<p className="wow txt" data-splitting>"Ash Naranjo, now an elite Ghost agent, was deployed into Freeland-occupied territory
									to capture Ethox – a high-ranking Freeland officer wanted for interrogation. Along the
									way, Ash travelled through a cave similar to one he and his sister had explored as kids,
									jogging his memory. At a Freeland mobile command center, Ash captures Ethox, but
									before leaving, Ash used Ethox’s credentials to search a database for three prisoners
									of war: Aurik, Isla, and Esme Naranjo. A fourth person, Zade Fernsell, was not in the
									database.
									But it doesn’t matter. Ash now remembers that he had a life
									before the Order, and he wants it back."
								</p>
								<ul>
									<li className="wow fadeInUp" data-wow-delay=".2s">Author: John Garvin</li>
									<li className="wow fadeInUp" data-wow-delay=".4s">Illustrator: Paul Pelletier</li>
								</ul>
								<br></br>
								<div className='row'>
									{
										(isThirdChapterAssociated && isThirdChapterOwner && isFounder) ?
											<div className='col-sm-6'>
												<p id='claimBtn'>Thank You For Participating in Our Airdrop</p> 
											</div>
											:
											(isThirdChapterAssociated && !isThirdChapterOwner && isFounder) ?
												<div className='col-sm-6'>
													<button className="butn bord curve wow fadeInUp custom-btn text-white" onClick={(() => claim("thirdChapter"))} data-wow-delay=".8s">
														<span>Claim Token</span>
													</button>
												</div>
												:
												(!isThirdChapterAssociated && !isThirdChapterOwner && isFounder) ?
													<div className='col-sm-6'>
														<button className="butn bord curve wow fadeInUp custom-btn text-white" onClick={associateToken} data-wow-delay=".8s">
															<span>Associate Token</span>
														</button>
													</div>
													:
													(isThirdChapterAssociated === false && isThirdChapterOwner === false && isFounder === false && isConnected === true)
														?
														<div className='col-sm-6'>
															<p id='claimBtn'> Sorry, you are ineligible for this drop. </p>
														</div>
														:
														<p id='claimBtn'> </p>
									}
									<div className='col-md-6'>
										<button className="butn bord curve wow fadeInUp custom-btn text-white" onClick={(() => download("third-chapter"))} data-wow-delay=".8s">
											<span>Download Comic</span>
										</button> 
									</div>
								</div>
								<br />
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="call-action" id='join-us'>
				<div id="videoContainer">
					<video id="video" autoPlay muted loop>
						<source src="/videos/intro.mp4" type="video/mp4" />
						Your browser does not support the video tag.
					</video>
				</div>
			</section>
			<section className="call-action section-padding bg-img join">
				{/* <div className="container"> */}
				<div className="row">
					<div className="col-md-6 col-lg-7 d-flex justify-content-center">
						<div className="content sm-mb30 pt-80 pb-80">
							<h6 className="wow" data-splitting>Let’s Talk</h6>
							<h2 className="wow" data-splitting>Join our <br /> <b className="back-color">Community</b>.</h2>
						</div>
					</div>

					<div className="col-md-4 col-lg-3 valign get-in-touch">
						<a href="https://discord.gg/liithos" className="butn bord curve wow fadeInUp custom-btn get-in-touch" data-wow-delay=".5s" target='blank'>
							<span>Get In Touch</span>
						</a>
					</div>
				</div>
				{/* </div> */}
			</section>

			<Modal
				ariaHideApp={false}
				isOpen={isOpen}
				onRequestClose={closeModal}
				contentLabel="My dialog"
				className="mymodal"
				overlayClassName="myoverlay"
				closeTimeoutMS={500}
			>
				<div className='close'>
					<button className="btn modal-btn float-right" onClick={closeModal}>
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M3 16.74L7.76 12L3 7.26L7.26 3L12 7.76L16.74 3L21 7.26L16.24 12L21 16.74L16.74 21L12 16.24L7.26 21L3 16.74m9-3.33l4.74 4.75l1.42-1.42L13.41 12l4.75-4.74l-1.42-1.42L12 10.59L7.26 5.84L5.84 7.26L10.59 12l-4.75 4.74l1.42 1.42L12 13.41Z" /></svg>
					</button>
				</div>

				<div>
					<h3 className='modal-text ml-4 mb-3'>
						scan the QR Code with your HashPack mobile app
					</h3>
					<QRCode value={pairingString} className='rounded mx-auto d-block mt-2 mb-50' />
				</div>
				<button className="btn--secondary mb-2" onClick={() => { addToClipBoard(pairingString) }}>Copy Pairing String</button>
				<button className="btn--primary mb-2" onClick={browserExtension}>
					<span>Browser Extension</span>
				</button>

			</Modal>

			<Modal
				ariaHideApp={false}
				isOpen={isFakeWallet}
				onRequestClose={closeFakeWalletModal}
				contentLabel="My dialog"
				className="mymodal2"
				overlayClassName="myoverlay"
				closeTimeoutMS={500}
			>
				<div className='close'>
					<button className="btn modal-btn float-right" onClick={closeFakeWalletModal}>
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M3 16.74L7.76 12L3 7.26L7.26 3L12 7.76L16.74 3L21 7.26L16.24 12L21 16.74L16.74 21L12 16.24L7.26 21L3 16.74m9-3.33l4.74 4.75l1.42-1.42L13.41 12l4.75-4.74l-1.42-1.42L12 10.59L7.26 5.84L5.84 7.26L10.59 12l-4.75 4.74l1.42 1.42L12 13.41Z" /></svg>
					</button>
				</div>

				<div className='mt-50'>
					<h3 className='modal-text ml-4 mb-3'>
						This address does not exist on Hedera Network.<br></br>
						Please try To login with a different wallet address.
					</h3>
				</div>

				<button className="btn--primary mb-2" onClick={closeFakeWalletModal}>
					<span>Okay</span>
				</button>

			</Modal>


			<Modal
				ariaHideApp={false}
				isOpen={loading}
				contentLabel="My dialog"
				className="loading-modal"
				overlayClassName="myoverlay"
				closeTimeoutMS={500}>
				<div className='d-flex justify-content-center'>
					<h3 className='modal-loading-text' >
						<span>{loadingMessage}</span> <div className="lds-dual-ring"></div>
					</h3>
				</div>
			</Modal>


			<br></br>
			<center className='new-footer'>
				<p>© 2023,  <a href="https://liithos.com">Liithos</a>.</p>
			</center>

		</>
	);
}

export default Home;