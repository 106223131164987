export const SAVE_USER_DATA = 'SAVE_USER_DATA';
export const SAVE_WALLET_DATA = 'SAVE_WALLET_DATA';

export const saveUserData = (userId: string, data:any, isConnected: boolean) => ({
  type: SAVE_USER_DATA,
  payload: { 
    userId: userId,
    isConnected: isConnected,
    isFirstChapterAssociated: data.isFirstChapterAssociated,
    isSecondChapterAssociated: data.isSecondChapterAssociated,
    isThirdChapterAssociated: data.isThirdChapterAssociated,
    isFirstChapterOwner: data.isFirstChapterOwner,
    isSecondChapterOwner: data.isSecondChapterOwner,
    isThirdChapterOwner: data.isThirdChapterOwner,
    isFounder: data.isFounder,
},
});

export const saveWalletData = (isFakeWallet: boolean) => ({
  type: SAVE_WALLET_DATA,
  payload: { 
    isFakeWallet: isFakeWallet,
},
});
